import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import CenteredColumns from "./CenteredColumns";
import Avatar from "./Avatar";
import "./TeamBiosSection2.scss";
import popoola from "../assets/img/popoola.png";
import captain from "../assets/img/captain-fana.png";
import lawrence from "../assets/img/lawrence.png";
import salami from "../assets/img/moses.png";
import rita from "../assets/img/rita.png";
import abiodun from "../assets/img/abiodun.png";
import ahmed from "../assets/img/ahmed.png";
import nehemaih from "../assets/img/nehemaih.png";
import fasan from "../assets/img/fasan.png";
import rabo from "../assets/img/rabo.png";
import idowu from "../assets/img/idowu.jpeg";
import sayi from "../assets/img/sayi.png";
import omolara from "../assets/img/omolara.png";
import shehu from "../assets/img/prof-shehu.png";
import achilike from "../assets/img/achilike.jpg";
import adeoye from "../assets/img/adeoye.jpg";
import anthony from "../assets/img/anthony.jpg";
import michael from "../assets/img/michael.jpg";
import edun from "../assets/img/edun.jpg";

function TeamBiosSection2(props) {
  const items = [
    {
      avatar: abiodun,
      name: "Dr. Abiodun Sule",
      role: "Executive Director/Chief Executive Officer, Nigeria Institute for Oceanography And Marine Research",
    },
    {
      avatar: idowu,
      name: "Dr. Olagunju Idowu Lateef",
      role: "Secretary General, NATCOM",
    },
    {
      avatar: popoola,
      name: (
        <>
          <p>Dr. Samuel Popoola</p>
        </>
      ),
      role: (
        <>
          <p>
            Chief Research Officer, Nigerian Institute for Oceanography And
            Marine Research
          </p>
        </>
      ),
    },
    {
      avatar: ahmed,
      name: "Prof. Ahmed Balogun",
      role: "Professor of Meteorology - Federal University of Technology, Akure",
    },
    {
      avatar: captain,
      name: "Navy Captain Mahmud Fana",
      role: "Hydrographic Office, Nigerian Navy Apapa, Lagos",
    },
    {
      avatar: rita,
      name: "Deaconess Rita Solari Inko-Tariah",
      role: "CEO - Solari Skills and Entrepreneurship Development Center (SSEDC) (NGO)",
    },
    {
      avatar: shehu,
      name: "Prof. Shehu Latunji Akintola",
      role: "Professor of Fisheries and HOD, Lagos State University.",
    },
    {
      avatar: lawrence,
      name: "Mr. Lawrence Wilbert",
      role: "Escravos Renewable Energy Limited (EREL)",
    },
    {
      avatar: sayi,
      name: "Mr. Emmanuel Sayi",
      role: "Legal Meterologists",
    },
    {
      avatar: omolara,
      name: "Ms. Omolara Irene Agboola",
      role: "Senior Technologist - National Water Resources Institute",
    },
    {
      avatar: fasan,
      name: "Fasan Olakunle",
      role: "Local Government Official",
    },
    {
      avatar: salami,
      name: "Mr. Moses Salami",
      role: "Student - Obafemi Awolowo University Ile Ife , Nigeria.",
    },
    {
      avatar: rabo,
      name: "Mr. Rabo Ibrahim",
      role: "Student – Water Resources Engineering ABU Zaria",
    },
    {
      avatar: nehemaih,
      name: "Nehemiah John Gyuk",
      role: "Special Assistant to the Focal Point Person: Nigerian Institute for Oceanography and Marine Research",
    },
    {
      avatar: achilike,
      name: "Dr. Mrs. Nkechi Achilike ",
      role: "Chief Reseach Officer - Nigerrian Institute for Oceanography and Marine Research",
    },
    {
      avatar: adeoye,
      name: "Mr. Olusola Matthew Adeoye",
      role: "Director of Coast and Ocean Research - Nature Cares Resources Centre",
    },
    {
      avatar: anthony,
      name: "Mr. Anthony Akpan",
      role: "President, Pan African Vision for the Environment (PAVE)",
    },
    {
      avatar: michael,
      name: "Mr. Michael Simire",
      role: "Managing Director,  EnviroNews",
    },
    {
      avatar: edun,
      name: "Dr. Olajumoke Edun",
      role: "Director, Nigerian Institute for Oceanography and Marine Research, Aquaculture Center, Buguma.",
    },
  ];

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        />
        <CenteredColumns>
          {items.map((item, index) => (
            <div
              className="column is-one-quarter has-text-centered"
              key={index}
            >
              <div className="TeamBiosSection2__person">
                <div className="TeamBiosSection2__avatar-wrapper">
                  <Avatar image={item.avatar} size={128} alt={item.name} />
                </div>
                <div className="TeamBiosSection2__details">
                  <p className="is-size-5">{item.name}</p>
                  <p className="is-size-7 has-text-weight-semibold has-text-grey">
                    {item.role}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </CenteredColumns>
      </div>
    </Section>
  );
}

export default TeamBiosSection2;
