import React from "react";
import HeroSection from "./../components/HeroSection";
import ClientsSection from "./../components/ClientsSection";
import HeroSection2 from "./../components/HeroSection2";
import TeamBiosSection2 from "./../components/TeamBiosSection2";
import TestimonialsSection from "./../components/TestimonialsSection";
import bgImage from "../assets/img/new-ocean-bg.jpg";

function IndexPage(props) {
  return (
    <>
      <HeroSection 
        color="primary"
        size="large"
        backgroundImage={bgImage}
        backgroundImageOpacity={1}
        title="UNESCO – IOC – NIGERIA NATIONAL DECADE COMMITTEE"
        subtitle="Tackling the several Ocean related issues through the exchange of ideas"
        buttonText="Get Started"
        buttonColor="white"
        buttonInverted={false}
        buttonPath="/pricing"
        style={{height: "90vh"}}
      />
      <ClientsSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Partner Organizations"
        subtitle=""
      />
      <HeroSection2
        color="white"
        size="large"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="UNESCO – IOC – NIGERIA NATIONAL DECADE COMMITTEE"
        subtitle="The UNESCO – IOC – NIGERIA NATIONAL DECADE COMMITTEE forum is a multidisciplinary stakeholder
        organization for Nigeria on behalf of UNESCO- INTERGOVERNMENTAL OCEANOGRAPHIC COMMISSION
        (IOC). This stakeholder group is established under the directive of the UNESCO-IOC through the office of
        the Nigerian National Commission for UNESCO ( NATCOM)."
        paragraphs={
          <>
            <p className="has-text-weight-bold is-size-3 mt-5">OUR MISSION</p>{" "}
            <p>
              To participate in all UN DECADE OF THE OCEAN Science for
              Sustainable Development activities, strengthen connections within
              Nigeria, and weave partnerships in communities working to study,
              conserve and sustainably use the ocean and its resources.
            </p>
          </>
        }
      />
      <TeamBiosSection2
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Meet the Members"
        subtitle=""
      />
      <TestimonialsSection
        title="Upcoming Events"
        color="white"
        size="medium"
      />
    </>
  );
}

export default IndexPage;
