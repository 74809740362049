import React from "react";
import "./../styles/global.scss";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import DashboardPage from "./dashboard";
import SettingsPage from "./settings";
import PurchasePage from "./purchase";
import AuthPage from "./auth";
import { Switch, Route, Router } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { AuthProvider } from "./../util/auth.js";

function App(props) {
  return (
    <AuthProvider>
      <Router>
        <>
          <Navbar color="white" spaced={true} logo="/" />

          <Switch>
            <Route exact path="/" component={IndexPage} />

            {/* <Route exact path="/about" component={AboutPage} />

            <Route exact path="/faq" component={FaqPage} />

            <Route exact path="/pricing" component={PricingPage} />

            <Route exact path="/contact" component={ContactPage} />

            <Route exact path="/dashboard" component={DashboardPage} />

            <Route exact path="/settings/:section" component={SettingsPage} />

            <Route exact path="/purchase/:plan" component={PurchasePage} />

            <Route exact path="/auth/:type" component={AuthPage} />

            <Route
              exact
              path="/firebase-action"
              component={FirebaseActionPage}
            /> */}

            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            color="light"
            size="medium"
            backgroundImage=""
            backgroundImageOpacity={1}
            description={
              <>
                <h1>CONTACT US THROUGH</h1>
                <p>The Focal Point for Nigeria:</p>
                <p>Dr. Samuel Popoola</p>
                <p>Nigerian Institute for Oceanography and Marine Research</p>
                <p>E-Mail: popoolaos@niomr.gov.ng</p>
                <p>E-Mail: lekanpopesam@gmail.com</p>
              </>
            }
            logo="https://uploads.divjoy.com/logo.svg"
          />
        </>
      </Router>
    </AuthProvider>
  );
}

export default App;
