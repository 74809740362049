import React from "react";
import Section from "./Section";
import { Link } from "./../util/router.js";
import logo from "../assets/img/logo.png"
import "./Footer.scss";

function Footer(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
      className="footer"
    >
      <div className="FooterComponent__container container">
        <div className="columns">
          <div className="column is-5 is-4-widescreen">
            <div className="brand has-text-color-primary is-size-2 has-text-weight-bold">
              <Link to="/">
              <figure class="image">
              <img src={logo} alt="Decade Committee Logo"/>
            </figure>
              </Link>
            </div>

            {props.description && (
              <p className="FooterComponent__description">
                {props.description}
              </p>
            )}

            {props.contact && (
              <p className="FooterComponent__copyright">{props.contact}</p>
            )}

            {props.copyright && (
              <p className="FooterComponent__copyright">{props.copyright}</p>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Footer;
