import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./ClientsSection.scss";
import unesco from "../assets/img/unesco-ioc.png";
import niomr from "../assets/img/niomr.jpeg";
import navy from "../assets/img/navy.svg";
import ncc from "../assets/img/ncc.png";
import nnh from "../assets/img/navy-hydro.jpeg";

function ClientsSection(props) {
  const items = [
    {
      name: "NIOMR",
      image: niomr,
      width: "85px",
    },
    {
      name: "UNESCO IOC",
      image: unesco,
      width: "185px",
    },
    {
      name: "Nigerian Communications Commission",
      image: ncc,
      width: "125px",
    },
    {
      name: "Nigerian Navy Hydrographic Office",
      image: nnh,
      width: "100px",
    },
    // {
    //   name: "Nigerian Navy",
    //   image: navy,
    //   width: "65px",
    // },
  ];

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        />
        <div className="columns is-centered is-multiline">
          {items.map((item, index) => (
            <div className="column is-narrow has-text-centered" key={index}>
              <div className="ClientsSection__logo">
                <img src={item.image} width={item.width} alt={item.name} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default ClientsSection;
