import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import CenteredColumns from "./CenteredColumns";
import parisEvent from "../assets/img/paris_fa.png";
import "./TestimonialsSection.scss";

function TestimonialsSection(props) {
  const items = [
    {
      avatar: parisEvent,
      name: "Sarah Kline",
      quote:
        "Engaging diverse stakeholders in the Ocean Decade: the key role of national and regional stakeholder networks",
      date: "18-05-2021",
      link: "https://oceandecade.com/events/203/Engaging-multiple-and-diverse-stakeholders-in-the-Ocean-Decade-the-key-role-of-national-and-regional-stakeholders-networks",
    },
    {
      avatar: parisEvent,
      name: "Sarah Kline",
      quote:
        "INDUSTRY JOURNALISTS CELEBRATE WORLD OCEAN DAY IN PORT HARCOURT",
      date: "18-05-2021",
      link: "",
    },
  ];

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        />
        <CenteredColumns>
          {items.map((item, index) => (
            <div className="column" key={index}>
              <figure className="testimonial">
                <blockquote className="TestimonialsSection__card card">
                  <article className="TestimonialsSection__media media">
                    <figure className="image is-3by1 is-fullwidth">
                      <img src={item.avatar} alt={item.name} />
                    </figure>
                  </article>
                  {/* <div className="subtitle is-size-6">Date: {item.date}</div> */}
                  <div className="TestimonialsSection__quote">{item.quote}</div>
                  <div className="has-text-link mt-3 is-clickable">
                    <a href={item.link} target="_blank" rel="noreferrer">
                      Click here to view more
                    </a>
                  </div>
                </blockquote>
              </figure>
            </div>
          ))}
        </CenteredColumns>
      </div>
    </Section>
  );
}

export default TestimonialsSection;
